import React, { useEffect, useState, useTransition } from "react";
import './home.css'

import OPLogo3 from '../../images/OPLogo4.png'
import greekPillarR from "../../images/home-icons-images/greek-column-1R-min.png"
import greekPillarL from "../../images/home-icons-images/greek-column-1L-min.png"
import lightningBolt from "../../images/home-icons-images/lightning-bolt1.webp"

// 1
// import weightGainImg from '../../images/home-icons-images/weight Gain Final.png'
// import muscleGainImg from '../../images/home-icons-images/muscle gain.png'
// import preWorkoutImg from '../../images/home-icons-images/pre workout final.png'
// import qualityBlendsImg from '../../images/home-icons-images/quality blends .png'
// import labTestedImg from '../../images/home-icons-images/lab tested final.png'
// import weightLossImg from '../../images/home-icons-images/weight loss final.png'
// 2
// import qualityBlendsImg from '../../images/home-icons-images/quality blends2.png'
// import labTestedImg from '../../images/home-icons-images/lab tested final2.png'
// import weightLossImg from '../../images/home-icons-images/weight loss final2.png'
// import weightGainImg from '../../images/home-icons-images/weight Gain Final2.png'
// import muscleGainImg from '../../images/home-icons-images/muscle gain2.png'
// import preWorkoutImg from '../../images/home-icons-images/pre workout final2.png'
// 3
import labTestedImg from '../../images/home-icons-images/Testing-beaker3.jpg'
import weightLossImg from '../../images/home-icons-images/WEIGHT-LOSS-v3.png'
import weightGainImg from '../../images/home-icons-images/MASS-GAIN-ICON-v3.png'
import muscleGainImg from '../../images/home-icons-images/MUSCLE-GAIN-ICON.jpg'



// Seals
// import abxSeal from '../../images/home-icons-images/anabolex-approved.png'
// import ioSeal from '../../images/home-icons-images/ironoverloadio-approved.png'
// import mesoSeal from '../../images/home-icons-images/meso-badge.jpg'
// import mcSeal from '../../images/home-icons-images/musclchemistry-approved.png'



import { Link } from "react-router-dom";
import { useCart } from "../../Comps/Cart/CartContext";

import globalProducts from "../../Comps/Products/products";
import productsBackend from "../../Comps/Products/productBackend";
import prodSubCategories from "../../Comps/Products/productSubCategories";
import siteWideDiscount from "../../Comps/Products/siteWideDiscount";
import Popup1 from "../../Comps/Extras/Discount-Popup/popup1";

const Home = () => {
  const [slideShowPosition, changeSlideShow] = useState([0,1,2,3])
  const [currSlideShowProducts, setCurrSlideShowProducts] = useState([]);
  const [popupAppearing, changePopupAppearance] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      changePopupAppearance(true)
    }, 1500)

    return () => clearTimeout(timer)
  }, []);

  // Homepage Products in Slideshow
  const slideShowProducts = [
    10019, 10046, 10056, 10076, 10084
    // globalProducts[17], //Test E 
    // globalProducts[44], //Primo E High
    // globalProducts[3], //Hades Wrath PreWorkout
    // globalProducts[54], //Anavar
    // globalProducts[74], //HCG
    // globalProducts[82], //Semaglutide
  ];

  const maxSlideShowPosition = slideShowProducts.length

  useEffect(() => {
    const result = slideShowProducts.map(id => globalProducts.find(product => product.productId === id));
    setCurrSlideShowProducts(result);
  }, [globalProducts]);

  // Slideshow Function
  const slideShowChanges = (id) => {
    let newPosition = [];
    if (id.target.id === "backBtn") {
      for (let i = 0; i<slideShowPosition.length; i++) {
        if (slideShowPosition[i] === 0) {
          newPosition.push(maxSlideShowPosition-1)
        } else {
          newPosition.push(slideShowPosition[i]-1)
        }
      }
    } else if (id.target.id === "nextBtn") {
        for (let j = 0; j<slideShowPosition.length; j++) {
          if (slideShowPosition[j] === maxSlideShowPosition-1) {
            newPosition.push(0)
          } else {
            newPosition.push(slideShowPosition[j]+1)
          }
        }
    }
    changeSlideShow(newPosition)

    return;
  }

  // Cart Items
  const { dispatch } = useCart()

  const addCartItem = (thisProduct) => {
    productsBackend.filter((item) => (
      item
    ));
    const thisCurrentProduct = productsBackend.find(item => item.productBackendId.toString() === (thisProduct.productId.toString() + '111'));
    
    const thisCurrentProductNew = 
      thisCurrentProduct.discountedPrice === null ? thisCurrentProduct
      : thisCurrentProduct.discountedPrice < 1 ? { ...thisCurrentProduct, price: thisCurrentProduct.price * thisCurrentProduct.discountedPrice }
      : thisCurrentProduct.discountedPrice >= 1 ? { ...thisCurrentProduct, price: thisCurrentProduct.price - thisCurrentProduct.discountedPrice }
      : thisCurrentProduct

    
    dispatch({ type: 'ADD_ITEM', payload: thisCurrentProductNew })
    return;
  }



  return (
    <div className="homepage-body">

    {siteWideDiscount.siteWide ? (
      popupAppearing ? <Popup1/> : ''
    ) : ''}

    <div id="greek-pillar-1">
      <img src={greekPillarR} alt="greekPillarR"/>
    </div>
    <div id="greek-pillar-2">
      <img src={greekPillarL} alt="greekPillarL"/>
    </div>
    <div id="lightning-bolt-1">
      <img src={lightningBolt} alt="lightningBolt"/>
    </div>
    <div id="lightning-bolt-2">
      <img src={lightningBolt} alt="lightningBolt"/>
    </div>

      {/* <div className="backgroundImgDiv">
        <img className="backgroundImg" src={backgroundImg} alt="backgroundImg"/>
      </div> */}

      {/* Section -1 */}
      {/* <div className="home-seals-div">
        <div>
          <h1>Trusted By:</h1>
        </div>
        <div className="home-inner-seals">
          <div>
            <Link target="_blank" to='https://anabolex.com/community/threads/olympianpharma-ltd-reviews-delivery-proof-and-feedback.13106/'>
              <img src={abxSeal} alt="abxSeal"/>
            </Link>
            <Link target="_blank" to='https://www.ironoverload.io/threads/olympianpharma-ltd-reviews-delivery-proof-and-feedback.3025/'>
              <img src={ioSeal} alt="ioSeal"/>
            </Link>
          </div>
          <div>
            <Link target="_blank" to='https://www.meso-morph.com/community/threads/olympianpharma-ltd-reviews-delivery-proof-and-feedback.1365/'>
              <img src={mesoSeal} alt="mesoSeal"/>
            </Link>
            <Link target="_blank" to='https://www.musclechemistry.com/forums/threads/olympianpharma-ltd-reviews-delivery-proof-and-feedback.139160/'>
              <img src={mcSeal} alt="mcSeal"/>
            </Link>
          </div>
        </div>
      </div> */}

      {/* Section 0 */}
      <div className="section-0-1">
      <div className="section-zero">
        <img className="section-zero-img1" src={OPLogo3} alt="OPLogo"/>
        <div className="section-zero-text">
          {/* <h2>Olympian Pharmaceutical</h2> */}
          {/* <img className="section-zero-logoTxt" src={logoText} alt="logoText"/> */}
          <h2>OLYMPIAN PHARMA</h2>
          <h3>Quality You Can Trust</h3>
          <hr/>
        </div>
      </div>

      {/* Section 1 */}
      <div className="section-one">
        {/* List of Positives */}
        <div className="section-one-positives">
          <div className="section-one-positives-l">
          <Link className="section-one-positives-link-div" 
          to='/store/specials/mass-gain'>
            <img src={weightGainImg} alt="Mass Gain"/>
            <h2>Mass Gain</h2>
          </Link>
          <hr className="section-one-hr"/>
          <Link className="section-one-positives-link-div" 
          to='/store/specials/muscle-gain'>
            <img src={muscleGainImg} alt="Muscle Gain"/>
            <h2>Muscle Gain</h2>
          </Link>
          <hr className="section-one-hr"/>
          {/* <Link className="section-one-positives-link-div" 
          to='/store/specials/pre-workout'>
            <img src={preWorkoutImg} alt="Pre Workout"/>
            <h2>Pre Workout</h2>
          </Link>
          <hr className="section-one-hr"/> */}
            </div>





            <div className="section-one-positives-r">
          {/* <Link className="section-one-positives-link-div" 
          to='/store/specials/quality-blends'
          >
            <img src={qualityBlendsImg} alt="Quality Blends"/>
            <h2>Quality Blends</h2>
          </Link>
          <hr className="section-one-hr"/> */}
          <Link className="section-one-positives-link-div" 
          // to='/store/specials/lab-tested'
          >
            <img src={labTestedImg} alt="Lab Tested"/>
            <h2>Lab Tested</h2>
          </Link>
          <hr className="section-one-hr"/>
          <Link className="section-one-positives-link-div" 
          to='/store/specials/weight-loss'>
            <img src={weightLossImg} alt="Weight Loss"/>
            <h2>Weight Loss</h2>
          </Link>
          </div>
        </div>
      </div>
      {/* double div */}
      </div>
      
      {/* Section 3 */}
      <div className="section-three">
        <div className="section-three-title">
          <Link className="shop-now-link" to='/store'>
            <h1 className="pulse-text">Shop Now!</h1>
          </Link>
          <hr/>
        </div>
        <div className="section-three-products">

          <div className="prev"><span id="backBtn" onClick={slideShowChanges}>&lt;</span></div>

          {slideShowPosition.map((position) => {
          const item = currSlideShowProducts[position];
          if (item) {
            return (
              <div className="section-three-product big-products" key={item.productId}>
                <Link
                  to={prodSubCategories
                    .filter((cat) => item.subCategory.toString() === cat.subCatId.toString())
                    .map(cat => `/store/` + cat.subName + '/' + item.productId)
                    .join('')}>
                  <img src={item.img} alt={item.name}/>
                </Link>
                <h3>{item.name}</h3>
                {item.discountedPrice === null ? (
                  <h2>${item.basePrice}.00</h2>
                ) : item.discountedPrice < 1 ? (
                  <div>
                    <h2 className="home-product-cost-slash">${item.basePrice}.00</h2>
                    <h2>${item.basePrice * item.discountedPrice}</h2>
                  </div>
                ) : item.discountedPrice >= 1 ? (
                  <div>
                    <h2 className="home-product-cost-slash">${item.basePrice}.00</h2>
                    <h2>${item.basePrice - item.discountedPrice}.00</h2>
                  </div>
                ) : (
                  <h2>${item.basePrice}.00</h2>
                )}
                <button onClick={() => addCartItem(item)}>
                  Add To Cart
                </button>
              </div>
            );
          } else {
            return null; // Return null if the item does not exist at the given position
          }
        })}
          
          <div className="next"><span id="nextBtn" onClick={slideShowChanges}>&gt;</span></div>

          {/* Responsive Products */}

          <div className="smaller-products">
          {slideShowPosition.map((position) => {
            const item = currSlideShowProducts[position];
            if (item) {
              return (
                <div className="section-three-product" key={item.productId}>
                  <Link
                    to={prodSubCategories
                      .filter((cat) => item.subCategory.toString() === cat.subCatId.toString())
                      .map(cat => `/store/` + cat.subName + '/' + item.productId)
                      .join('')}>
                    <img src={item.img} alt="vile"/>
                  </Link>
                    
                  <h3>{item.name}</h3>
                  {item.discountedPrice === null ? (
                    <h2>${item.basePrice}.00</h2>
                  ) : item.discountedPrice < 1 ? (
                    <div>
                      <h2 className="home-product-cost-slash">${item.basePrice}.00</h2>
                      {/* <br/> */}
                      <h2>${item.basePrice * item.discountedPrice}</h2>
                    </div>
                  ) : item.discountedPrice >= 1 ? (
                    <div>
                      <h2 className="home-product-cost-slash">${item.basePrice}.00</h2>
                      {/* <br/> */}
                      <h2>${item.basePrice - item.discountedPrice}.00</h2>
                    </div>
                  ) : (
                    <h2>${item.basePrice}.00</h2>
                  )}
                  <button onClick={() => addCartItem(item)}>
                    Add To Cart
                  </button>
                </div>
              );
            } else {
              return null; // Return null if the item does not exist at the given position
            }
          })}
          </div>

          {/* <div className="smaller-products">
              <div className="section-three-product">
                <Link 
                to={prodSubCategories
                  .filter((cat) => slideShowProducts[slideShowPosition[0]].subCategory.toString() === cat.subCatId.toString())
                  .map(cat => 
                  `/store/` + cat.subName + '/' + slideShowProducts[slideShowPosition[0]].productId)
                  .join('')}>
                  <img src={slideShowProducts[0].img} alt="vile"/>
                </Link>
                <h3>{slideShowProducts[0].name}</h3>
                <h2>${slideShowProducts[0].basePrice}.00</h2>
                <button onClick={() => addCartItem(slideShowProducts[slideShowPosition[0]])}>
                  Add To Cart
                </button>
              </div>
              <div className="section-three-product">
                <Link 
                to={prodSubCategories
                  .filter((cat) => slideShowProducts[slideShowPosition[1]].subCategory.toString() === cat.subCatId.toString())
                  .map(cat => 
                  `/store/` + cat.subName + '/' + slideShowProducts[slideShowPosition[1]].productId)
                  .join('')}>
                  <img src={slideShowProducts[1].img} alt="vile"/>
                </Link>
                <h3>{slideShowProducts[1].name}</h3>
                <h2>${slideShowProducts[1].basePrice}.00</h2>
                <button onClick={() => addCartItem(slideShowProducts[slideShowPosition[1]])}>
                  Add To Cart
                </button>
              </div>
              <div className="section-three-product">
                <Link 
                to={prodSubCategories
                  .filter((cat) => slideShowProducts[slideShowPosition[2]].subCategory.toString() === cat.subCatId.toString())
                  .map(cat => 
                  `/store/` + cat.subName + '/' + slideShowProducts[slideShowPosition[2]].productId)
                  .join('')}>
                  <img src={slideShowProducts[2].img} alt="vile"/>
                </Link>
                <h3>{slideShowProducts[2].name}</h3>
                <h2>${slideShowProducts[2].basePrice}.00</h2>
                <button onClick={() => addCartItem(slideShowProducts[slideShowPosition[2]])}>
                  Add To Cart
                </button>
              </div>
              <div className="section-three-product">
                <Link 
                to={prodSubCategories
                  .filter((cat) => slideShowProducts[slideShowPosition[3]].subCategory.toString() === cat.subCatId.toString())
                  .map(cat => 
                  `/store/` + cat.subName + '/' + slideShowProducts[slideShowPosition[3]].productId)
                  .join('')}>
                  <img src={slideShowProducts[3].img} alt="vile"/>
                </Link>
                <h3>{slideShowProducts[3].name}</h3>
                <h2>${slideShowProducts[3].basePrice}.00</h2>
                <button onClick={() => addCartItem(slideShowProducts[slideShowPosition[3]])}>
                  Add To Cart
                </button>
              </div>
            </div> */}
          </div>

        </div>


        {/* Section 2 */}
      <div className="section-two">
        <div>
          <h2>Money Back Guarantee</h2>
          <p>30 day money back guarantee</p>
        </div>
          <hr/>
        <div>
          <h2>Online Support</h2>
          <p>24/7 Support</p>
        </div>
          <hr/>
        <div>
          <h2>Safe Shopping</h2>
          <p>Safe Shopping Guarantee</p>
        </div>
          <hr/>
        <div>
          <h2>Free Shipping</h2>
          <p>Free shipping on all orders above $500</p>
        </div>
      </div>
    </div>
  )
}

export default Home;