import React, { useEffect, useState } from "react";
import './header.css';
import { Link } from "react-router-dom";

import globalProducts from "../Products/products";
import prodSubCategories from "../Products/productSubCategories";
import { useCart } from '../../Comps/Cart/CartContext';

const Header = () => {
  const [navMenuOpen, changeNavMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchVisible, setSearchVisible] = useState(false);
  const { totalItems } = useCart();
  
  useEffect(() => {
    const referrer = document.referrer || "No referrer";
    const storedReferrer = localStorage.getItem('referrer');

    if (referrer && !storedReferrer) {
      localStorage.setItem('referrer', referrer);
      console.log("Referrer saved to localStorage:", referrer);
    }
  }, []);


  const toggleNavMenu = () => {
    changeNavMenuOpen(!navMenuOpen);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const toggleSearchVisibility = () => {
    setSearchVisible(!searchVisible);
    setSearchValue(''); // Clear search value when toggling
  };

  const handlePageChange = () => {
    setSearchVisible(false)
    setSearchValue('')
  }
  

  const filteredData = globalProducts.filter(item =>
    item.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  

  return (
    <div className='header-outside-div'>
      <div>
        <Link className="top-nav-link" to="">
          <h3 className={searchVisible ? "change-heading-on-hover top-nav-link-dis-name" : ""}>Olympian Pharmaceutical</h3>
        </Link>
      </div>
      <nav className="reactive-header-nav">
        <Link className="top-nav-link" to="/store">
          <h3>Store</h3>
        </Link>
        {/* <Link className="top-nav-link" to="/test-reports">
          <h3>Test Reports</h3>
        </Link> */}
        <Link className="top-nav-link" to="/about">
          <h3>About</h3>
        </Link>
        <Link className="top-nav-link" to="/faqs">
          <h3>FAQs</h3>
        </Link>
      </nav>
      <div className="right-nav reactive-header-nav">
        {searchVisible && (
          <div className="search-container">
            <input
              type="text"
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search..."
              className="search-input"
            />
            {searchValue && (
              <ul className={searchValue === '' ? 'hidden' : "search-results"}>
                {filteredData.map((item, index) => (
                  <Link 
                  onClick={handlePageChange} 
                  className="search-outcome-link" 
                  to={prodSubCategories
                    .filter((cat) => item.subCategory.toString() === cat.subCatId.toString())
                    .map(cat => 
                    `/store/` + cat.subName + '/' + item.productId)
                    .join('')
                    }>
                  <li key={index} className="fade-in">
                    {item.name}
                  </li>
                  </Link>
                ))}
              </ul>
            )}
          </div>
        )}
        <div className="search-icon" onClick={toggleSearchVisibility}>
          🔎
        </div>
        <Link className="top-nav-link" to='/cart'>
          <div>
            🛒
            <span className={totalItems !== 0 ? "cart-item-span" : 'hidden'}>
            {totalItems !== 0 ? totalItems : ""}
            </span>
          </div>
        </Link>
      </div>

        {/* Reactive Right Menu */}
      <div className="reactive-right-nav">
        {searchVisible && (
          <div className="reactive-search-container">
            <input
              type="text"
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search..."
              className="reactive-search-input"
            />
            {searchValue && (
              <ul className={searchValue === '' ? 'hidden' : "reactive-search-results"}>
                {filteredData.map((item, index) => (
                  <Link 
                  onClick={handlePageChange} 
                  className="search-outcome-link" 
                  to={prodSubCategories
                    .filter((cat) => item.subCategory.toString() === cat.subCatId.toString())
                    .map(cat => 
                    `/store/` + cat.subName + '/' + item.productId)
                    .join('')
                    }>
                  <li key={index} className="fade-in">
                    {item.name}
                  </li>
                  </Link>
                ))}
              </ul>
            )}
          </div>
        )}
        <div className="reactive-search-icon" onClick={toggleSearchVisibility}>
          🔎
        </div>
        
        <Link className="reactive-cart-icon" to="/cart">
          <div>
            🛒
          </div>
        </Link>
        <span className={totalItems !== 0 ? "cart-item-span-responsive" : 'hidden'}>
            {totalItems !== 0 ? totalItems : ""}
        </span>
        <div onClick={toggleNavMenu} className="reactive-nav-ham">
          <div className={`bar ${navMenuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${navMenuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${navMenuOpen ? 'open' : ''}`}></div>
        </div>
      </div>

      <div className={`mobile-nav-menu ${navMenuOpen ? 'openNav' : 'hidden'}`}>
        <nav>
          <ul>
            <li>
              <Link onClick={toggleNavMenu} className="mobile-nav-link" to="/">
                <h3>Home</h3>
              </Link>
            </li>
            <li>
              <Link onClick={toggleNavMenu} className="mobile-nav-link" to="/store">
                <h3>Store</h3>
              </Link>
            </li>
            {/* <li>
              <Link onClick={toggleNavMenu} className="mobile-nav-link" to="/test-reports">
                <h3>Test Reports</h3>
              </Link>
            </li> */}
            <li>
              <Link onClick={toggleNavMenu} className="mobile-nav-link" to="/about">
                <h3>About</h3>
              </Link>
            </li>
            <li>
              <Link onClick={toggleNavMenu} className="mobile-nav-link" to="/faqs">
                <h3>FAQs</h3>
              </Link>
            </li>
            {/* <li>
              <Link onClick={toggleNavMenu} className="mobile-nav-link" to="/cart">
                <h3>🛒</h3>
              </Link>
            </li> */}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
